import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Tag } from '@veneer/core'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${primitives.layout.size4}px;
  padding-bottom: ${primitives.layout.size6}px;
`
export const PageDesc = styled.div`
  margin-bottom: 25px;
`
export const ProgressBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100px;
`
export const SettingsFlexRow = styled.div`
  display: flex;
`
export const SettingsCategories = styled.div`
  width: 310px;
  li[role='treeitem'] {
    div: first-of-type {
      margin-left: 0px;
      ul {
        margin-left: 30px;
        padding: 0 0 0 20px;
      }
      .total-children {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 20px;
        background: rgba(33, 33, 33, 0.1);
        border-radius: 12px;
      }
    }
  }
`
export const SettingsSelection = styled.div`
  width: calc(100% - 310px);
`
export const CategoriesTitle = styled.div`
  font-size: ${primitives.typography.size5} !important;
  line-height: 28px;
  color: #404040;
  margin-bottom: ${primitives.layout.size3}px !important;
  font-style: normal;
  font-weight: 400;
`
export const CategoriesTree = styled.div`
  overflow-x: auto;
  padding-right: 24px;
`
export const SearchForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-grow: 1;
`
export const ActionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const ActionAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const ActionAreaFilterTags = styled.div`
  display: flex;
  width: 100%;
`
export const StyledFilterTag = styled(Tag)`
  gap: 8px;
  background-color: rgba(2, 120, 171, 0.1) !important;
  display: flex;
  padding: 4px 12px;
  height: 28px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 5px 5px 5px 0;

  > span {
    font-size: 16px;
    color: #014667;
    overflow: inherit;
    text-overflow: inherit;
  }
  svg {
    fill: #014667;
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
  }
  [role='button']:hover {
    background-color: transparent;
  }
`
