/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  UIEvent
} from 'react'
import { ProxySettingsList } from '../../interfaces/proxyInterface'
import { ErrorWidget, SmartTooltip } from '@jarvis/react-portal-addons'
import { filter, formattedName } from '../../utils/commonMethods'
import {
  ProxySettingsConstants,
  defaultOrderColumn,
  defaultpageSizeOptions,
  preferences
} from '../../utils/constants'

import {
  ColumnPiece,
  SettingName,
  ClickToAddProxy,
  ToggleColumn
} from './styles'
import { useShellRootContext } from '../../contexts/ShellRoot'
import {
  IconHelp,
  IconWarningAlt,
  Toggle,
  Tag,
  Table,
  PageSizeInterface,
  Tooltip
} from '@veneer/core'
import HelpInfoModal from '../../Shared-Components/Modals/HelpInfoModal'
import WarningModal from '../../Shared-Components/Modals/WarningModal'
import { updateProxySettings } from '../../utils/api'
const ProxySettingsData = ({
  currentCategory,
  actionArea,
  proxySettings,
  searchProxySettingsItem,
  searchSettingsItems,
  isLoading,
  hasError,
  retriveProxySettings
}) => {
  const { t, interfaces } = useShellRootContext?.() || {}
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const authProvider = interfaces?.v2.authProvider || {}
  const key = (subkey: string): string => {
    return t(
      `ecp-fleet-proxy-settings.attributes.${subkey}.name`,
      formattedName(subkey)
    )
  }
  /* istanbul ignore next */
  const keyDescr = (subkey: string): string => {
    return t(
      `ecp-fleet-proxy-settings.attributes.${subkey}.description`,
      formattedName(subkey)
    )
  }
  const catKey = (subkey: string): string => {
    return t(
      `ecp-fleet-proxy-settings.categories.${subkey}`,
      formattedName(subkey)
    )
  }

  type ColumnIndexTypes = 'visible' | 'hidden' | 'none'

  type proxySettingsListColumnstype = {
    id: string
    label: string
    index?: ColumnIndexTypes
    required?: boolean
    sortable?: boolean
  }

  const proxySettingsListColumns: Array<proxySettingsListColumnstype> = [
    {
      id: 'settingName',
      label: t('ecp-fleet-proxy-settings.settingName', 'Setting Name'),
      required: true
    },
    {
      id: 'category',
      label: t('ecp-fleet-proxy-settings.category', 'Category')
    },
    {
      id: 'allowSelected',
      label: ''
    },
    {
      id: 'settingId',
      label: 'settingId',
      index: 'hidden'
    }
  ]
  const createProxySettingsData = (settingsData: ProxySettingsList[]) => {
    const getTableData = (
      name: string,
      category: string,
      dataTestId: string
    ) => {
      return (
        <ColumnPiece>
          <SmartTooltip
            content={key(name)}
            portal
            useJsPositioning
            contentShowDelayOnHover={0}
            contentHideDelayOnHover={0}
          >
            <SettingName
              className="label"
              data-testid={dataTestId}
            >
              {key(name)}
            </SettingName>
          </SmartTooltip>
          <IconHelp
            onClick={() => (
              setShowHelpInfoModal(true),
              setHelpInfoModalTitle(key(name)),
              setHelpInfoModalContent(keyDescr(name))
            )}
          />
          {category === 'necessary-data' ? (
            <Tooltip
              arrow
              portal
              placement="bottom"
              content={t(
                'ecp-fleet-proxy-settings.necessaryDataToolTip',
                'Necessary data are essential to ensuring the basic functionality of the Fleet Proxy service and the printers in your fleet. If you do not allow HP to collect data elements essential for basic functionality your service will be significantly imacted. Some or all of the features associated with printers may no longer operate.'
              )}
              id="tooltip"
            >
              <Tag
                label={t(
                  'ecp-fleet-proxy-settings.necessaryLabel',
                  'Necessary'
                )}
                customStyle={{
                  background: 'transparent',
                  color: '#585858',
                  border: '1px solid #585858',
                  height: '20px',
                  'justify-content': 'center',
                  'border-radius': '10px'
                }}
              />
            </Tooltip>
          ) : (
            ''
          )}
        </ColumnPiece>
      )
    }
    const getToggleData = (
      id: string,
      name: string,
      category: string,
      selected: boolean
    ) => {
      return (
        <ToggleColumn>
          {!selected && category === 'necessary-data' ? (
            <IconWarningAlt
              filled
              color={'darkOrange6'}
            />
          ) : (
            ''
          )}
          <Toggle
            label={t('ecp-fleet-proxy-settings.allow', 'Allow')}
            id={id}
            data-testid="toggle-settings"
            name={name}
            on={selected}
            onChange={() => handleToggle(name, category, !selected)}
          />
        </ToggleColumn>
      )
    }
    return settingsData.map((setting, index) => ({
      ...setting,
      settingName: getTableData(setting.name, setting.category, 'setting-name'),
      name: formattedName(setting.name),
      nonFormatedCategory: setting.category,
      category: catKey(setting.category),
      allow: setting.allow,
      allowSelected: getToggleData(
        setting.id,
        setting.name,
        setting.category,
        setting.allow === 'enabled' ? true : false
      ),
      settingId: index,
      id: setting.id
    }))
  }

  const [currentPage, setCurrentPage] = useState<number>(
    ProxySettingsConstants.currentPage
  )
  const [settingsData, setSettingsData] = useState<ProxySettingsList[]>([])
  const [proxyPageData, setProxyPageData] = useState<ProxySettingsList[]>([])
  const [totalItems, setTotalItems] = useState<number>(proxySettings.length)
  const [showHelpInfoModal, setShowHelpInfoModal] = useState<boolean>(false)
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
  const [settingName, setSettingName] = useState<string>('')
  const [settingCategory, setSettingCategory] = useState<string>('')
  const [settingAllow, setSettingAllow] = useState<string>('')
  const [isWarningLoading, setIsWarningLoading] = useState<boolean>(false)
  const [helpInfoModalTitle, setHelpInfoModalTitle] = useState<string>('')
  const [helpInfoModalContent, setHelpInfoModalContent] = useState<string>('')
  const [pageSize, setPageSize] = useState<number>(
    ProxySettingsConstants.pageSize
  )

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (
    event: UIEvent<HTMLElement>,
    option: PageSizeInterface
  ) => {
    event.preventDefault()
    setPageSize(option.value)
  }

  /* istanbul ignore next */
  const handleToggle = async (
    name: string,
    category: string,
    selected: boolean
  ) => {
    const allow = selected ? 'enabled' : 'disabled'
    if (category === 'necessary-data' && !selected) {
      setShowWarningModal(true)
      setSettingName(name)
      setSettingCategory(category)
      setSettingAllow(allow)
    } else {
      await updateProxySettings(name, category, allow, authProvider, authStack)
      retriveProxySettings()
    }
  }
  /* istanbul ignore next */
  const updateSettingAllow = useCallback(
    async (name: string, category: string, allow: string) => {
      setIsWarningLoading(true)
      try {
        await updateProxySettings(
          name,
          category,
          allow,
          authProvider,
          authStack
        )
        retriveProxySettings()
        setShowWarningModal(false)
      } catch (err) {
        console.warn('Error while updating settings toggle' + name, err)
      } finally {
        setIsWarningLoading(false)
      }
    },
    [retriveProxySettings]
  )

  useEffect(() => {
    const filterData = filter(
      settingsData,
      searchProxySettingsItem,
      searchSettingsItems,
      currentCategory
    )
    setTotalItems(filterData.length)
    const currentStartIndex = (currentPage - 1) * pageSize
    const startIndex =
      filterData.length > currentStartIndex ? currentStartIndex : 0
    const pageData: ProxySettingsList[] = filterData.slice(
      startIndex,
      startIndex + pageSize
    )
    setProxyPageData(pageData)
    setTimeout(() => {
      handlePageChange(filterData.length > pageSize ? currentPage : 1)
    }, 100)
  }, [
    proxySettings,
    isLoading,
    searchProxySettingsItem,
    searchSettingsItems,
    settingsData,
    pageSize,
    currentCategory
  ])
  const setPaginationData = () => {
    const startIndex = (currentPage - 1) * pageSize
    const pageData: ProxySettingsList[] = filter(
      settingsData,
      searchProxySettingsItem,
      searchSettingsItems,
      currentCategory
    ).slice(startIndex, startIndex + pageSize)
    setProxyPageData(pageData)
  }

  useEffect(() => {
    const getSettingsData = createProxySettingsData(proxySettings)
    setSettingsData(getSettingsData)
  }, [proxySettings, isLoading])

  useEffect(() => {
    setPaginationData()
  }, [currentPage, pageSize])

  const customNoItems = useMemo(() => {
    if (hasError) {
      return (
        <div data-testid="error-proxies-list">
          <ErrorWidget
            onRetry={retriveProxySettings}
            message={t(
              'ecp-fleet-proxy-settings.unableToLoad',
              'Unable to Load Data'
            )}
            labelRetry={t('ecp-fleet-proxy-settings.retry', 'Retry')}
          />
        </div>
      )
    } else if (proxySettings.length === 0) {
      return (
        <div data-testid="error-proxies">
          <ErrorWidget
            message={t(
              'ecp-fleet-proxy-settings.noProxies',
              'No Settings Found.'
            )}
          />
          <ClickToAddProxy>
            {t(
              'ecp-fleet-proxy-settings.clickToAddProxies',
              'When a proxy is added, a settings list that are allowed to be collected will appear here.'
            )}
          </ClickToAddProxy>
        </div>
      )
    } else {
      return (
        <div data-testid="error-empty-proxies-list">
          <ErrorWidget
            message={t(
              'ecp-fleet-proxy-settings.noResultsFound',
              'No Results Found in Proxies List'
            )}
          />
          <ClickToAddProxy>
            {t(
              'ecp-fleet-proxy-settings.selectOtherFilters',
              'You can select other filters or adjust your search keywords.'
            )}
          </ClickToAddProxy>
        </div>
      )
    }
  }, [hasError, retriveProxySettings, t])

  const data = hasError ? [] : proxyPageData
  return (
    <>
      <Table
        actionArea={actionArea}
        data-testid="proxy-settings-data-table"
        columns={proxySettingsListColumns}
        data={data}
        preferences={{
          ...preferences,
          headerNoWrap: true,
          defaultOrder: defaultOrderColumn
        }}
        loading={isLoading}
        loadingDataLength={5}
        pagination={{
          currentPage: currentPage,
          onPageChange: totalItems !== 0 ? handlePageChange : undefined,
          onPageSizeChange: totalItems !== 0 ? handlePageSizeChange : undefined,
          pageSize: pageSize,
          pageSizeOptions: defaultpageSizeOptions,
          popoverPlacement: 'top-start',
          totalItems
        }}
        component={'table'}
        customNoItems={customNoItems}
      />
      <HelpInfoModal
        data-testid="help-info-modal"
        modalTitle={helpInfoModalTitle}
        onClose={() => {
          setShowHelpInfoModal(false)
        }}
        showModal={showHelpInfoModal}
        modalContent={helpInfoModalContent}
      />
      <WarningModal
        data-testid="settings-warning-modal"
        onClose={() => {
          setShowWarningModal(false)
        }}
        showModal={showWarningModal}
        settingName={settingName}
        settingCategory={settingCategory}
        settingAllow={settingAllow}
        onConfirm={updateSettingAllow}
        isLoading={isWarningLoading}
      />
    </>
  )
}

export default ProxySettingsData
