import { TablePreferences } from '@veneer/core'

export const ProxySettingsConstants = {
  name: 'name',
  settingName: 'settingName',
  category: 'category',
  allow: 'allow',
  allowSelected: 'allowSelected',
  ascending: 'ascending',
  pageSize: 25,
  currentPage: 1
}

export const defaultpageSizeOptions = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 }
]

export const defaultOrderColumn = ['settingName', 'category', 'allowSelected']

export const preferences: TablePreferences = {
  width: [
    {
      columnId: 'settingName',
      width: 200
    },
    {
      columnId: 'category',
      width: 200
    },
    {
      columnId: 'allowSelected',
      width: 150
    }
  ]
}
export const SettingsStatus = [{ value: 'Warning', label: 'warning' }]

export const SettingsDataCollection = [
  { value: 'Allow', label: 'allow' },
  { value: 'Disallow', label: 'disAllow' }
]

export const ProxySettingsStatusValues = SettingsDataCollection.map(
  (item) => item.value
)
export const SettingsWarningStatus = SettingsStatus.map((item) => item.value)
