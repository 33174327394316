import React from 'react'
import { IconWarningAlt } from '@veneer/core'
import GenericModal from '../GenericModal'
import { WarningModalContent } from './style'
import { useShellRootContext } from '../../../contexts/ShellRoot'
import { formattedName } from '../../../utils/commonMethods'

/* istanbul ignore next */
const WarningModal = ({
  onClose,
  showModal,
  settingName,
  settingCategory,
  settingAllow,
  onConfirm,
  isLoading
}) => {
  const { t } = useShellRootContext?.() || {}
  return (
    <GenericModal
      dataTestId={'warning-modal-modal'}
      id={'warning-modal-modal'}
      onClose={onClose}
      show={showModal}
      customModalWidth={'612px'}
      customModalDescWidth={'327px'}
      header={{
        leadingIcon: (
          <IconWarningAlt
            color="darkOrange6"
            filled={true}
            size={48}
          />
        ),
        title: t(
          'ecp-fleet-proxy-settings.functionalityLost',
          'Functionality May Be Lost'
        ),
        dataTestId: 'warning-modal-title'
      }}
      footer={{
        buttons: {
          cancel: {
            label: t('ecp-fleet-proxy-settings.cancel', 'Cancel'),
            onClick: onClose,
            dataTestId: 'warning-modal-cancel-btn',
            disabled: isLoading
          },
          proceed: {
            colorScheme: 'negative',
            label: t('ecp-fleet-proxy-settings.confirm', 'Confirm'),
            onClick: () => {
              onConfirm(settingName, settingCategory, settingAllow)
            },
            disabled: isLoading,
            loading: isLoading,
            dataTestId: 'warning-modal-btn'
          }
        }
      }}
    >
      <>
        <WarningModalContent data-testid="warning-modal-content">
          {t(
            'ecp-fleet-proxy-settings.warningModalDescr',
            'Collection of this data is essential to ensure basic functionality of your printers and the Fleet Proxy service. If you do not allow HP to collect this data, your service will be significantly impacted. Some, or all, of the features associated with your printers may no longer operate.'
          )}
        </WarningModalContent>
        <WarningModalContent>{formattedName(settingName)}</WarningModalContent>
        <WarningModalContent>
          {t(
            'ecp-fleet-proxy-settings.areYouSureToRemove',
            'Are you sure you want to save this data collection preference?'
          )}
        </WarningModalContent>
      </>
    </GenericModal>
  )
}

export default WarningModal
