import React from 'react'
import { HelpInfoModalFooter } from './style'
import GenericModal from '../GenericModal'

const HelpInfoModal = ({ modalTitle, onClose, showModal, modalContent }) => {
  return (
    <GenericModal
      dataTestId={'help-info-modal'}
      id={'help-info-modal'}
      onClose={onClose}
      show={showModal}
      closeButton={true}
      customModalWidth={'800px'}
      header={{
        title: modalTitle,
        dataTestId: 'help-info-modal-title'
      }}
      footer={{
        buttons: {
          cancel: {
            className: 'hideButton',
            label: ''
          },
          proceed: {
            label: '',
            className: 'hideButton'
          }
        }
      }}
    >
      <HelpInfoModalFooter
        dangerouslySetInnerHTML={{ __html: modalContent }}
      ></HelpInfoModalFooter>
    </GenericModal>
  )
}

export default HelpInfoModal
