import React from 'react'
import { useShellRootContext } from '../../contexts/ShellRoot'
import {
  DescrSpan,
  TitleSpan,
  WarningContainer,
  WarningContent
} from './styles'
import { IconWarningAlt } from '@veneer/core'
const ProxyWarningAlt = () => {
  const { t } = useShellRootContext?.() || {}
  return (
    <WarningContainer>
      <IconWarningAlt
        filled
        color={'darkOrange6'}
      />
      <WarningContent>
        <TitleSpan>
          {t(
            'ecp-fleet-proxy-settings.functionalityLost',
            'Functionality May Be Lost'
          )}
        </TitleSpan>
        <DescrSpan>
          {t(
            'ecp-fleet-proxy-settings.warningDescr',
            'Necessary data has been set to not allow data collection. This type of data is essential to ensuring the basic functionality of the printers in your fleet.'
          )}
        </DescrSpan>
      </WarningContent>
    </WarningContainer>
  )
}

export default ProxyWarningAlt
