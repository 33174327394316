import { ProxySettingsList } from '../interfaces/proxyInterface'
import { FleetProxySettingsProps } from '../components/ProxieSettingsMainPage/type'

let localprops: FleetProxySettingsProps = {
  language: 'en',
  country: 'US'
}

export const setProps = (props) => {
  localprops = props
}

export const getProps = (): FleetProxySettingsProps => {
  return localprops
}

export const filter = (
  settingsListData: Array<ProxySettingsList>,
  searchItem: string,
  searchItems: string[],
  currentCategory: string
) => {
  return settingsListData.filter((proxySetting) => {
    const isMatchingSearch =
      (searchItem === '' ||
        proxySetting.name.toLowerCase().includes(searchItem.toLowerCase()) ||
        proxySetting.category
          .toLowerCase()
          .includes(searchItem.toLowerCase())) &&
      (currentCategory === null ||
        proxySetting.nonFormatedCategory
          .toLowerCase()
          .includes(currentCategory.toLowerCase()))
    const isMatchingStatus =
      searchItems.length === 0 ||
      (searchItems.includes('Allow') && proxySetting.allow === 'enabled') ||
      (searchItems.includes('Disallow') && proxySetting.allow === 'disabled') ||
      (searchItems.includes('Warning') &&
        proxySetting.category === 'Necessary Data' &&
        proxySetting.allow === 'disabled')
    return isMatchingSearch && isMatchingStatus
  })
}
export const formattedName = (inputString: string): string => {
  const words = inputString.split('-')
  const uppercasedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  )
  return uppercasedWords.join(' ')
}
