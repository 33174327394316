import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const WarningContent = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin: 8px 8px 8px 0px;
  word-break: break-word;
`
export const WarningContainer = styled.div`
  margin-bottom: 16px;
  align-items: flex-start;
  display: flex;
  background-color: ${primitives.color.darkOrange0};
  padding: 8px;
  border-radius: 8px;

  svg {
    width: 24px;
    height: 24px;
    margin: 8px 8px 8px 0px;
    position: relative;
    vertical-align: middle;
    color: ${primitives.color.darkOrange6};
  }
`
export const TitleSpan = styled.span`
  font-size: 16px;
  line-height: 20px;
`
export const DescrSpan = styled.span`
  font-size: 12px;
  line-height: 16px;
`
