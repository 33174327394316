import React from 'react'
import { Button, ButtonInterface } from '@veneer/core'
import { StyledModal, VeneerButtonGroup } from './styles'
import Header from './Header'

type ButtonProps = {
  label: string
  dataTestId?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
} & ButtonInterface

type GenericModalProps = {
  children?: React.ReactNode
  id: string
  onClose?: () => void
  show?: boolean
  dataTestId?: string
  closeButton?: boolean
  header: {
    dataTestId?: string
    title: string
    leadingIcon?: React.ReactNode
  }
  footer: {
    buttons: {
      proceed: ButtonProps
      cancel: ButtonProps
    }
  }
  customModalWidth?: string
  customModalDescWidth?: string
}

const GenericModal = ({
  children,
  id,
  onClose,
  show,
  dataTestId,
  header,
  footer,
  closeButton,
  customModalWidth,
  customModalDescWidth
}: GenericModalProps) => {
  const {
    dataTestId: proceedButtonTestId,
    label: proceedButtonLabel,
    ...proceedButtonProps
  } = footer.buttons.proceed
  const {
    dataTestId: cancelButtonTestId,
    label: cancelButtonLabel,
    ...cancelButtonProps
  } = footer.buttons.cancel
  return (
    <StyledModal
      id={id}
      closeButton={closeButton}
      data-testid={dataTestId}
      footer={
        <VeneerButtonGroup>
          <Button
            appearance="secondary"
            data-testid={cancelButtonTestId}
            {...cancelButtonProps}
          >
            {cancelButtonLabel}
          </Button>
          <Button
            data-testid={proceedButtonTestId}
            {...proceedButtonProps}
          >
            {proceedButtonLabel}
          </Button>
        </VeneerButtonGroup>
      }
      onClose={onClose}
      show={show}
      customModalWidth={customModalWidth}
      customModalDescWidth={customModalDescWidth}
      closeOnBlur={false}
    >
      <Header
        dataTestId={header.dataTestId}
        leadingIcon={header.leadingIcon}
        title={header.title}
      />
      {children}
    </StyledModal>
  )
}

export default GenericModal
