import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Button } from '@veneer/core'

export const FilterSidebarContainer = styled.div`
  div[aria-label='Backdrop'] {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
  div[id='filter-sidebar'] {
    display: flex;
    flex-direction: column;
    padding: ${primitives.layout.size6}px;
  }
  #filter-sidebar-container {
    padding: ${primitives.layout.size6}px;
  }
`
export const FilterSidebarPrimaryTitle = styled.p`
  font-size: ${primitives.typography.size5};
  line-height: ${primitives.typography.lineHeight5};
  margin-bottom: 9px;
`
export const FilterSidebarPrimaryDesc = styled.p`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
  margin-bottom: ${primitives.layout.size4}px;
`
export const SearchFiltersDiv = styled.form`
  width: 302px;
  margin-bottom: 35px;
`

export const FilterHolder = styled.div`
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  overflow: auto;
  margin-right: -${primitives.layout.size6}px;
  #favouriteList,
  #connectivityList,
  #customList {
    ul {
      li {
        border: 0;
        height: ${primitives.typography.lineHeight7};
        padding: 0 ${primitives.layout.size2}px;
        span {
          font-size: ${primitives.typography.size2};
          line-height: ${primitives.typography.lineHeight2};
        }
        button {
          padding: 0;
        }
        button:first-child {
          margin-right: ${primitives.layout.size3}px;
        }
      }
      li:hover {
        background-color: #f4f4f4;
      }
    }
  }
  #connectivityList,
  #customList {
    ul {
      li {
        .hidden-child {
          visibility: hidden;
        }
      }
      li:hover {
        .hidden-child {
          visibility: visible;
        }
      }
    }
  }
  ul[role='listbox'] {
    li:hover {
      background-color: ${primitives.color.hpBlue7} !important;
    }
  }
`
export const FilterList = styled.div`
  width: 302px;
  margin-bottom: 10px;
`

export const FilterLabel = styled.p`
  float: left;
  width: 115px;
  font-size: ${primitives.typography.size2};
  color: ${primitives.color.gray4};
  line-height: ${primitives.layout.size5}px;
`
export const Divider = styled.hr`
  float: right;
  width: 175px;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
  margin: 10px 0;
`
export const MarginBottom60 = styled.div`
  width: 100%;
  margin-bottom: 60px;
`
export const PrimaryFooter = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 307px;
  height: 49px;
  padding: 0 24px 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-top: 1px solid rgba(33, 33, 33, 0.1);
`
export const SelectedItemsText = styled.p`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight3};
  text-align: left;
`
export const ClearAllButton = styled(Button)`
  max-width: 83px;
  min-width: 83px;
  height: ${primitives.typography.lineHeight6};
  &:hover {
    background-color: transparent !important;
  }
`
