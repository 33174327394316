/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, FleetProxyConnectorSvcClient } from '@jarvis/web-stratus-client'

export const getDataFiltersList = async (authProvider, stack) => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  let settiingsList
  const continueLoop = true
  while (continueLoop) {
    try {
      settiingsList = await client.getDataFilters()
      if (settiingsList.status === 200) {
        break
      } else {
        await new Promise((resolve) => setTimeout(resolve, 10000))
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return null
      } else {
        await new Promise((resolve) => setTimeout(resolve, 10000))
      }
    }
  }
  return settiingsList
}
/* istanbul ignore next */
export const getSettings = async (authProvider, stack) => {
  const settiingsList = await getDataFiltersList(authProvider, stack)
  const necessaryData =
    settiingsList != null
      ? settiingsList.data.items
          .filter((item) => item.category === 'necessary-data')
          .sort((a, b) => a.name.localeCompare(b.name))
      : []
  const otherData =
    settiingsList != null
      ? settiingsList.data.items.filter(
          (item) => item.category !== 'necessary-data'
        )
      : []
  const sortedOtherData = otherData.slice().sort((a, b) => {
    const categoryComparison = a.category.localeCompare(b.category)
    if (categoryComparison !== 0) {
      return categoryComparison
    }
    return a.name.localeCompare(b.name)
  })
  const combinedData = [...necessaryData, ...sortedOtherData]
  return combinedData
}
/* istanbul ignore next */
export const getSettingsCategories = async (authProvider, stack) => {
  const settiingsList = await getDataFiltersList(authProvider, stack)
  const necessaryCategories = Array.from(
    new Set(
      settiingsList != null
        ? settiingsList.data.items
            .filter((item) => item.category === 'necessary-data')
            .map((item) => item.category)
        : []
    )
  )
  const otherCategories = Array.from(
    new Set(
      settiingsList != null
        ? settiingsList.data.items
            .filter((item) => item.category !== 'necessary-data')
            .map((item) => item.category)
            .sort((a, b) => a.localeCompare(b))
        : []
    )
  )
  const combinedCategories = [...necessaryCategories, ...otherCategories]
  return combinedCategories
}

export const getEnabledSettingCount = (data, category: string) => {
  return data.reduce((count, item) => {
    if (item.category === category && item.allow === 'enabled') {
      return count + 1
    }
    return count
  }, 0)
}

export const getWarningAlt = async (data) => {
  const resp = data.filter(
    (item) => item.category === 'necessary-data' && item.allow !== 'enabled'
  )
  return resp
}

export const updateProxySettings = async (
  name: string,
  category: string,
  allow: string,
  authProvider,
  stack: Stack
): Promise<any> => {
  const client = new FleetProxyConnectorSvcClient(stack, authProvider)
  const response = await client.updateDataFilters(name, category, allow)
  return response
}
