import React from 'react'
import { ModalHeader, ModalHeaderIcon } from './styles'

export type HeaderProps = {
  leadingIcon?: React.ReactNode
  title: string
  dataTestId?: string
}

const Header = ({ leadingIcon, title, dataTestId }) => (
  <ModalHeader>
    {leadingIcon && <ModalHeaderIcon>{leadingIcon}</ModalHeaderIcon>}
    <h4 data-testid={dataTestId}>{title}</h4>
  </ModalHeader>
)

export default Header
