import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Select } from '@veneer/core'

export const ActionSelect = styled(Select)`
  && {
    min-width: 224px;
    background-color: white;
    border-radius: 12px;

    @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
      min-width: initial;
      width: 100%;
    }
  }
`

export const SettingName = styled.span`
  max-width: 325px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`

export const ClickToAddProxy = styled.div`
  margin-top: 8px;
`
export const ColumnPiece = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  gap: 8px;
  svg {
    width: 23px;
    height: 23px;
    position: relative;
    vertical-align: middle;
    color: ${primitives.color.hpBlue6};
    cursor: pointer;
  }
`
export const ReorderColumnPortal = styled.div`
  .vn-modal--footer div {
    flex-direction: row-reverse;
    gap: 12px;
  }
`
export const AllowBlock = styled.div`
  align-items: flex-end;
`
export const ToggleColumn = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  align-items: center;
  white-space: nowrap;
  justify-content: end;
  label {
    margin-right: 20px;
  }
  svg {
    width: 23px;
    height: 23px;
    margin-left: 2px;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
  }
`
