import React from 'react'
import { useShellRootContext } from '../../contexts/ShellRoot'
import { CardContent, CardText } from './styles'
const ProxyPageDescription = () => {
  const { t } = useShellRootContext?.() || {}
  return (
    <CardContent>
      <CardText>
        {t(
          'ecp-fleet-proxy-settings.descrPreferences',
          'To manage your data collection preferences, use the toggles to choose what data HP Command Center collects from printers in your fleet.'
        )}
      </CardText>
    </CardContent>
  )
}

export default ProxyPageDescription
