/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SideBar, Checkbox, List, ListItem, IconX, Search } from '@veneer/core'
import { useShellRootContext } from '../../contexts/ShellRoot'

import {
  FilterLabel,
  Divider,
  MarginBottom60,
  PrimaryFooter,
  SelectedItemsText,
  ClearAllButton
} from './FilterSidebarStyles'
import {
  ProxySettingsStatusValues,
  SettingsWarningStatus
} from '../../utils/constants'
import { formattedName } from '../../utils/commonMethods'
import * as S from './FilterSidebarStyles'

const FilterSidebar = ({ onclose, performFilter, appliedFilter }) => {
  const [searchFilterValue, setSearchFilterValue] = useState<string>('')
  const [showInvalidSearchMsg, setShowInvalidSearchMsg] =
    useState<boolean>(false)
  const { t } = useShellRootContext?.() || {}
  const [statusLabels, setStatusLabels] = useState([])
  const [warningStatusLabels, setWarningStatusLabels] = useState([])

  const handleSearchFilterValue = (value: string) => {
    setSearchFilterValue(value)
  }

  const handleClearSearch = () => {
    setSearchFilterValue('')
    setStatusLabels(ProxySettingsStatusValues)
    setWarningStatusLabels(SettingsWarningStatus)
    setShowInvalidSearchMsg(false)
  }

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const filterStatusdata = ProxySettingsStatusValues.filter((proxy) =>
      proxy.toLowerCase().includes(searchFilterValue.toLowerCase())
    )
    const filterWarningStatusdata = SettingsWarningStatus.filter((proxy) =>
      proxy.toLowerCase().includes(searchFilterValue.toLowerCase())
    )
    const filteredData = [...filterStatusdata, ...filterWarningStatusdata]
    filteredData.length === 0
      ? setShowInvalidSearchMsg(true)
      : setShowInvalidSearchMsg(false)
    setStatusLabels(filterStatusdata)
    setWarningStatusLabels(filterWarningStatusdata)
  }

  const handleFilterCheck = (e) => {
    e.target.checked
      ? performFilter(e.target.value, 'push')
      : performFilter(e.target.value, 'pop')
  }

  const key = (subkey: string): string => {
    return t(`ecp-fleet-proxy-settings.${subkey}`, formattedName(subkey))
  }
  useEffect(() => {
    setStatusLabels(ProxySettingsStatusValues)
    setWarningStatusLabels(SettingsWarningStatus)
  }, [])

  const handleCheckboxChange = (e: {
    target: EventTarget & HTMLInputElement
  }) => {
    handleFilterCheck(e)
    fillOrRemoveFromArr(e.target)
  }

  const [arr, setArr] = useState<Array<EventTarget & HTMLInputElement>>([])

  const fillOrRemoveFromArr = (target: EventTarget & HTMLInputElement) => {
    target.checked
      ? setArr([...arr, target])
      : setArr(arr.filter((item) => item !== target))

    return null
  }

  const handleAllUncheck = (): void => {
    for (const value of arr) {
      value.checked = false
      setArr([])
    }
    return null
  }

  const mainContent = (
    <>
      <S.FilterSidebarPrimaryTitle data-testid="filter-sidebar-primary-title">
        {t('ecp-fleet-proxy-settings.filter', 'Filter')}
      </S.FilterSidebarPrimaryTitle>
      <S.FilterSidebarPrimaryDesc data-testid="filter-sidebar-description">
        {t(
          'ecp-fleet-proxy-settings.selectFilters',
          'Select filters to view in the list.'
        )}
      </S.FilterSidebarPrimaryDesc>
      <S.SearchFiltersDiv
        data-testid="search-filters-div"
        id="search-filters-div"
        onSubmit={handleSearch}
      >
        <Search
          data-testid="search-filters-box"
          id="search-filters-box"
          className="marginR11 floatL"
          placeholder={t(
            'ecp-fleet-proxy-settings.searchFilters',
            'Search Filters'
          )}
          onChange={handleSearchFilterValue}
          onClear={handleClearSearch}
          content={undefined}
          nonce={undefined}
          rel={undefined}
          rev={undefined}
        ></Search>
      </S.SearchFiltersDiv>
      {!showInvalidSearchMsg && (
        <>
          {warningStatusLabels.length != 0 && (
            <S.FilterHolder>
              <S.FilterList id="warningStatusList">
                <>
                  <FilterLabel>
                    {t('ecp-fleet-proxy-settings.warningStatus', 'Status')}
                  </FilterLabel>
                  <Divider></Divider>
                </>
                <List>
                  {SettingsWarningStatus &&
                    warningStatusLabels.map((warningLabel) => (
                      <ListItem
                        key={warningLabel}
                        data-testid="warning-block-fliters"
                        id="warning-block-fliters"
                        leadingArea={
                          <Checkbox
                            data-testid="warning-block-fliters-checkbox"
                            id={warningLabel}
                            className="lablesCheckBox"
                            name="warningBlockFliters"
                            label={key(warningLabel.toLowerCase())}
                            value={warningLabel}
                            onChange={handleCheckboxChange}
                            checked={
                              appliedFilter.includes(warningLabel)
                                ? true
                                : arr.includes(
                                      arr.filter(
                                        (item) => item.id === warningLabel
                                      )[0]
                                    )
                                  ? true
                                  : false
                            }
                          />
                        }
                      ></ListItem>
                    ))}
                </List>
              </S.FilterList>
            </S.FilterHolder>
          )}
          {statusLabels.length != 0 && (
            <S.FilterHolder>
              <S.FilterList id="connectivityList">
                <>
                  <FilterLabel>
                    {t(
                      'ecp-fleet-proxy-settings.connectivity',
                      'Data Collection'
                    )}
                  </FilterLabel>
                  <Divider></Divider>
                </>
                <List>
                  {ProxySettingsStatusValues &&
                    statusLabels.map((statusLabel) => (
                      <ListItem
                        key={statusLabel}
                        data-testid="filter-block-2-item-1"
                        id="filter-block-2-item-1"
                        leadingArea={
                          <Checkbox
                            data-testid="filter-block-2-checkbox-1"
                            id={statusLabel}
                            className="lablesCheckBox"
                            name="lablesCheckBox"
                            label={key(statusLabel.toLowerCase())}
                            value={statusLabel}
                            onChange={handleCheckboxChange}
                            checked={
                              appliedFilter.includes(statusLabel)
                                ? true
                                : arr.includes(
                                      arr.filter(
                                        (item) => item.id === statusLabel
                                      )[0]
                                    )
                                  ? true
                                  : false
                            }
                          />
                        }
                      ></ListItem>
                    ))}
                </List>
              </S.FilterList>

              <MarginBottom60></MarginBottom60>
            </S.FilterHolder>
          )}
        </>
      )}
      {showInvalidSearchMsg && (
        <div
          data-testid="not-available-span"
          className="not-available-span"
        >
          {t(
            'ecp-fleet-proxy-settings.searchedfilterNotAvailable',
            'Searched filter not available'
          )}
        </div>
      )}

      {appliedFilter.length ? (
        <PrimaryFooter data-testid="filtersidebar-primary-footer">
          <SelectedItemsText data-testid="filtersidebar-selecteditems-text">
            {appliedFilter.length}
            {` `}
            {t('ecp-fleet-proxy-settings.itemsSelected', 'items selected')}
          </SelectedItemsText>
          <ClearAllButton
            data-testid="filtersidebar-clearall-button"
            appearance="tertiary"
            leadingIcon={<IconX />}
            onClick={() => {
              handleAllUncheck()
              performFilter('', 'clearall')
            }}
          >
            {t('ecp-fleet-proxy-settings.clearAll', 'Clear all')}
          </ClearAllButton>
        </PrimaryFooter>
      ) : null}
    </>
  )

  const getContent = () => {
    return <>{mainContent}</>
  }

  return (
    <S.FilterSidebarContainer>
      <SideBar
        data-testid="filter-sidebar-container"
        id="filter-sidebar-container"
        behavior="overlay"
        position="end"
        disableBackdropClick
        size={302}
        show={true}
        content={getContent()}
        onClose={() => onclose()}
      />
    </S.FilterSidebarContainer>
  )
}

FilterSidebar.propTypes = {
  onclose: PropTypes.func.isRequired
}

export default FilterSidebar
